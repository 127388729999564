import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { animationConfig } from '@app/angular-animation/animation';
import { selectUI, selectDataBus } from '@app/store/selectors';
import { showIdleState } from '@app/store/actions';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { HeaderComponent } from '../header/header.component';
import { AppConfig } from '@environment/environment';
import { NgClass, NgIf } from '@angular/common';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'app-idle-state',
    templateUrl: './idle-state.component.html',
    styleUrls: ['./idle-state.component.scss'],
    animations: [animationConfig.fadeInOut],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        HeaderComponent,
        VideoPlayerComponent,
        TranslateModule,
        LottieComponent,
    ],
})
export class IdleStateComponent implements OnInit, OnDestroy {
    isEmergencyStateEnabled: boolean = false;
    showIdleState: boolean = true;
    dataBusSubscription: any;
    idleStateContent = [];
    currentPreview: any;
    currentPreviewIndex = 0;
    timeOutIdle;

    options: AnimationOptions = {
        path: 'assets/lottie/touch-screen.json',
    };

    infoOptions: AnimationOptions = {
        path: 'assets/lottie/info.json',
    };

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        const uiState$ = this.store.select(selectUI);

        uiState$.subscribe((uiItem) => {
            this.showIdleState = uiItem.windows.showIdleState;
        });

        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.isEmergencyStateEnabled =
                dataBusItem.emergencyBroadcast.enabled;

            this.idleStateContent = dataBusItem.idleState;
        });
    }

    ngAfterViewInit() {
        this.rotateList();
    }

    getImageURL(imageName: string): string {
        return imageName
            ? `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.storageBucket}/o/idleState%2F${imageName}_1920x1080.webp?alt=media`
            : null;
    }

    rotateList() {
        if (!this.idleStateContent || this.idleStateContent.length === 0) {
            this.currentPreview = null;
            this.currentPreview = 0;
            return;
        }

        this.currentPreview = null;

        setTimeout(() => {
            this.currentPreview =
                this.idleStateContent[this.currentPreviewIndex];

            if (this.idleStateContent.length - 1 === this.currentPreviewIndex) {
                this.currentPreviewIndex = 0;
            } else {
                this.currentPreviewIndex = this.currentPreviewIndex + 1;
            }

            this.timeOutIdle = setTimeout(() => {
                this.rotateList();
            }, this.currentPreview.playLength * 1000);
        }, 10);
    }

    ngOnDestroy() {
        this.dataBusSubscription.unsubscribe();
        clearTimeout(this.timeOutIdle);
    }

    hideIdleState() {
        if (!this.isEmergencyStateEnabled) {
            this.googleAnalyticService.onStateChange('start');
            this.store.dispatch(showIdleState({ showIdleState: false }));
        }
    }
}
